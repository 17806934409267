import * as React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image";
import Grid from '@material-ui/core/Grid';
import SEO from "../components/seo"


const ContactPage = () => {
    
    return (<Layout currentPage="contact" overridePadding>
        <SEO title={'Solutions'} 
          description="We have the solutions for your business"/>
        <StaticImage src="../images/contactus.png"
            quality={95}
            placeholder={'TRACED_SVG'}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Metalinguist"
            fadeIn={true} 
            loading="eager"
            style={{height: '350px'}}/>
        <Grid container justify="center">
<iframe src='https://formcrafts.com/a/zuduhnr?iframe=true&resizer=false&innerHeight=869' width='1000' height='869' style={{maxWidth:'100%', border: 0, boxShadow: '0 0 0 .5px rgba(30, 30, 30, .1), 0px 1px 2px rgb(30, 30, 30, .08)', borderRadius: '3px'}}></iframe>
        </Grid>
    </Layout>
)}

export default ContactPage;